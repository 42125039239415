import React from "react";

// import AppBar from "./AppBar";
// import Introduction from "./Introduction";
// import Variants from "./Variants";
// import Dashboards from "./Dashboards";
// import Features from "./Features";
// import FAQ from "./FAQ";
// import JoinUs from "./JoinUs";
import { Redirect } from "react-router-dom";

function Presentation() {
  return (
    // <React.Fragment>
    //   <AppBar />
    //   <Introduction />
    //   <Variants />
    //   <Dashboards />
    //   <Features />
    //   <FAQ />
    //   <JoinUs />
    // </React.Fragment>
    <Redirect to="/auth/sign-in" />
  );
}

export default Presentation;
