import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { green, red } from "@material-ui/core/colors";

import Actions from "./Actions";
// import BarChart from "./BarChart";
import LineChart from "./LineChart";
// import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
// import Table from "./Table";
import VerticalSlider from "./Slider";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
} from "@material-ui/core";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

function Default() {
  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            SmartPoles Dashboard
          </Typography>
          <Typography variant="subtitle1">
            South Bridge Road, Singapore.
            {/* <span role="img" aria-label="Waving Hand Sign">
              👋
            </span> */}
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Power"
            amount="100 W"
            chip="Today"
            percentageText="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Voltage"
            amount="230 V"
            chip="Today"
            percentageText="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Current"
            amount="340 mA"
            chip="Today"
            percentageText="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Alarm"
            amount="0"
            chip="Today"
            percentageText="No Alarms"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={8}>
          <LineChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" mb={4}>
                Luminance control
              </Typography>
              <Typography align="center" padding="30px">
                <VerticalSlider />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <BarChart />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Table />
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}

export default Default;
