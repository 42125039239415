import React from "react";
// import styled from "styled-components/macro";
// import { Link } from "react-router-dom";

// import { Helmet } from "react-helmet-async";

import { Typography } from "@material-ui/core";
// import { spacing } from "@material-ui/system";
import wip from "./wip.png";

// const Button = styled(MuiButton)(spacing);

// const Wrapper = styled.div`
//   padding: ${(props) => props.theme.spacing(6)}px;
//   text-align: center;
//   background: transparent;

//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: ${(props) => props.theme.spacing(10)}px;
//   }
// `;

function Blank() {
  return (
    <div>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Not Available on demo version
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        <img src={wip} width="300" height="200" alt="WIP" />
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {/* Watch this space later for updates. */}
      </Typography>
    </div>
  );
}

export default Blank;
