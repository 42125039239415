import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles({
  root: {
    height: 300,
  },
});

const marks = [
  {
    value: 100,
    label: "ON",
  },
  {
    value: 85,
    label: "85",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 0,
    label: "OFF",
  },
];
function valuetext(value) {
  return `${value}°C`;
}

const IOSSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    margin: "0",
    padding: "25px",
    border: "1px solid",
  },
  active: {},
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  markLabel: {
    marginLeft: "12px",
    padding: "0 10px 0px 10px",
    border: "1px solid",
  },
})(Slider);

export default function VerticalSlider() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography id="vertical-slider" gutterBottom></Typography>
      <div className={classes.root}>
        <IOSSlider
          orientation="vertical"
          aria-label="ios slider"
          defaultValue={60}
          getAriaValueText={valuetext}
          marks={marks}
          valueLabelDisplay="on"
        />
        <div className={classes.margin} />
      </div>
    </React.Fragment>
  );
}
